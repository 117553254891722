/* eslint-disable */
import client from '@/http/client';

// Utils
import { camelToSnake } from '@/utils/formatters';

const BASE_URL = '/organizations';

class OrganizationsService {
  getContractors({ limit, offset, isArchived, search, orderBy } = {}) {
    return client.get(`${BASE_URL}/contractors/`, {
      params: {
        limit,
        offset,
        isArchived,
        search,
        orderBy: camelToSnake(orderBy),
      },
    });
  }

  async getContractorById(id) {
    const contractor = await client.get(`${BASE_URL}/contractors/${id}/`);
    const originData = { ...contractor };
    originData.bankName = originData.bankDetails.name;
    originData.bankLegalName = originData.bankDetails.legalName;
    originData.bankIban = originData.bankDetails.iban;
    originData.bankSwift = originData.bankDetails.swift;

    return originData;
  }
}

export default new OrganizationsService();
