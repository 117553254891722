import * as schemaItemTypes from './schemaItemTypes';
import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    label: 'contractor.general_information',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'contractor.type',
            type: schemaItemTypes.STRING,
            prop: 'contractorType',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'contractor.director_name',
            type: schemaItemTypes.STRING,
            prop: 'directorName',
            rules: [rules.REQUIRED],
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'contractor.registration_number',
            type: schemaItemTypes.STRING,
            prop: 'registrationNumber',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'contractor.country',
            type: schemaItemTypes.COUNTRY,
            prop: 'country',
            rules: [rules.REQUIRED],
            dictionary: dictionaryTypes.COUNTRIES,
            size: 3,
          },
          {
            label: 'contractor.vat',
            type: schemaItemTypes.STRING,
            prop: 'vatNumber',
            rules: [rules.REQUIRED],
            size: 3,
          },
        ],
      },
    ],
  },
  {
    label: 'contractor.contact',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'contractor.address',
            type: schemaItemTypes.STRING,
            prop: 'address',
            rules: [rules.REQUIRED],
          },
          {
            label: 'contractor.contact_person',
            type: schemaItemTypes.STRING,
            prop: 'contactPerson',
            rules: [rules.REQUIRED],
          },
          {
            label: 'contractor.phone',
            type: schemaItemTypes.STRING,
            prop: 'phone',
            rules: [rules.REQUIRED, rules.PHONE],
          },
          {
            label: 'contractor.email',
            type: schemaItemTypes.STRING,
            prop: 'email',
            rules: [rules.REQUIRED, rules.EMAIL],
          },
        ],
      },
    ],
  },

  {
    label: 'contractor.bank_details',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'contractor.bank',
            type: schemaItemTypes.STRING,
            prop: 'bankName',
            rules: [rules.REQUIRED],
          },
          {
            label: 'contractor.legal_name',
            type: schemaItemTypes.STRING,
            prop: 'bankLegalName',
            rules: [rules.REQUIRED],
          },
          {
            label: 'contractor.iban',
            type: schemaItemTypes.STRING,
            prop: 'bankIban',
            rules: [rules.REQUIRED],
          },
          {
            label: 'contractor.swift',
            type: schemaItemTypes.STRING,
            prop: 'bankSwift',
            rules: [rules.REQUIRED],
          },
        ],
      },
    ],
  },
];

export { schema as default };
